const commonTranslation = {
    pleaseSelect: "Veuillez sélectionner...",
    callSign: "Indicatif",
    email: "E-mail",
    emailPlaceholder: "E-mail",
    website: "Site web",
    vat: "Tva intra",
    name: "Nom",
    namePlaceHolder: "Entrer votre nom",
    firstNamePlaceHolder: "Entrer votre prénom",
    firstname: "Prénom",
    cityOfBirth: "Ville de naissance",
    countryOfBirth: "Pays de naissance",
    birthday: "Date de naissance",
    share: "Partager",
    tags: "Catégorie",
    owner: "Propriétaire",
    venue: "Lieu",
    applyFilter: "Appliquer le filtre",
    resetFilter: "Réinitialiser les filtres",
    filters: "Filtres de recherche",
    localStartDate: "Date de début",
    localEndDate: "Date de fin",
    loading: "Chargement en cours...",
    pagination: "Affichage de {{startItemNumber}} à {{endItemNumber}} sur {{totalItems}} éléments",
    save: "Sauvegarder",
    close: "Fermer",
    password: "Mot de passe",
    noAccount: "Vous n'avez pas encore de compte ?",
    createAccount: "Créer un compte",
    forgotPassword: "Mot de passe oublié",
    canceled: "Annuler",
    pages: "pages",
    perPage: "par page",
    adminInfo: "Informations administratives",
    imatProof: "Justificatifs d'immatriculation ou publication",
    managementInformation: "Informations sur la gérance",
    paymentMethod: "Mode de virement et paiement",
    myEvents: "Mes événements",
    shareWithMe: 'Partagés avec moi',
    title: "Titre",
    action: "Action",
    importedAt: "Importé le",
    dateShortWithSecond: "{{date, SHORT_WITH_SECONDS}}",
    status: "Statut",
    inputRequired: "Ce champ est requis !",
    invalidEmail: "Adresse e-mail invalide",
    siret: "Siret",
    pleaseEnterPhoneNumber: "Téléphone",
    attachmentRequired: "Les justificatifs : ({{value}}) sont obligatoire.",
    legalInformation: "Renseignements juridiques",
    localOffices: "Bureaux Locaux",
    localOffice: "Bureau Local",
    addOffice: "Ajouter un bureau",
    removeOffice: "Retirer le bureau",
    invalidPhoneNumber: "Numéro de téléphone invalide",
    manager: "Dirigeant",
    addManager: "Ajouter un dirigeant",
    removeManager: "Retirer le manager",
    paymentMethods: "Mode de virement et paiement",
    invoices: "Factures",
    invoiceNumber: "N° de Facture",
    unitPrice: "Prix unitaire",
    invoiceSentDate: "Date d'émission",
    pleaseSelectPaymentMode: "Veuillez selectionner un mode de paiement",
    transfert: "Virement",
    card: "Carte",
    pleaseEnterCardNumber: "Entrer le n° de carte",
    cardNumber: "N° de carte",
    expirationDate: "Date d'expiration",
    CVV: "CVV",
    inputMinLength: "Ce champ doit contenir au moins {{value}}",
    inputMaxLength: "Ce champ doit contenir au maximum {{value}}",
    invalidExpirationDateFormat: "Le format attendu est MM/AA, MM=Mois , AA= Année",
    download: "Télécharger",
    iban: "IBAN",
    initializeCompanyAccount: "Initialisation de Compte",
    businessName: "Nom commercial",
    personContact: "Personne contact",
    pleaseEnterCompanyName: "Entrer le nom de l'entreprise",
    pleaseEnterCompanyBusinessName: "Entrer le nom commercial",
    pleaseEnterCompanyPersonContact: "Entrer le nom de personne contact",
    pleaseEnterCompanyEmail: "Entrer l'e-mail de contact",
    add: "Ajouter",
    accountType: "Type de compte",
    lastLogin: "Dernière connexion",
    company: "Compagnie",
    createdAt: "Créer le",
    updatedAt: "Mise à jour le",
    lastUpdatedAt: "Dernière mise à jour le",
    source: "Source",
    address: "Adresse",
    organisation: "Compagnie",
    roles: "Roles",
    inputURLInvalid: "URL non valide ex: https://ticketr;fr",
    position: "Titre de votre poste",
    pleaseEnterYourPosition: "Entrer votre poste",
    legalInformationRetrievingMethod: "Comment souhaitez-vous renseigner les informations légales de votre organisation ?",
    prev: "Prec",
    next: "Suiv",
    manual: "Manuellement plus tard",
    byMySiren: "Via mon n° Siren",
    byMySiret: "Via mon n° Siret",
    pleaseEnter: "Entrez le numéro",
    retrieve: "Récupéré",
    invalidSiretNumber: "Le numéro saisi est incorrect",
    inputSiretLength: "Ce champs doit contenir {{value}} characters",
    stepOne: "Étape 1 ",
    stepTwo: "Étape 2 ",
    stepThree: "Etape 3 ",
    saveAndRegister: "Sauvegarder et s'inscrire",
    formSubmitted: "Votre formulaire a été soumis avec succès",
    willBeContacted: "Vous serez contacté(e) dès que possible",
    backToLogin: "Retour à la connexion",
    wellcomeText: "Rejoignez la communauté ticketr et bénéficiez de nombreux canaux de vente supplémentaire et le tout gratuitement",
    wellcomeLoginButtonText: "Se connecter",
    notHaveAccount: "Vous n'avez pas de compte ? Aucun souci !",
    testForFree: "Testez gratuitement",
    registerAsPro: "Inscrivez-vous en tant que professionnel",
    eventDetails: "Détails d'un événement",
    pleaseEnterToAdd: "Tapez quelque chose et appuyez sur Entrée pour ajouter",
    eventBruteDetails: "Données brutes",
    linkToTicketing: "Accéder à la billeterie",
    //linkToTicketing: "Réserver",
    pleaseEnterName: "Entrer un nom",
    pleaseEnterAddress: "Entrer une adresse",
    phone: "Téléphone",
    export: "Export",
    exportTo: "Exporter en {{value}}",
    errorWhileShareEvent: "Oups! Echec du partage",
    city: "Ville",
    postalCode: "Code postal",
    country: "Pays",
    moreDetails: "Plus de détails",
    canBeIdentified: "Oups! Nous ne parvenons pas à vous authentifier",
    pleaseTryAgainOrContact: "Ceci est dû au fait que vous ne disposez pas de compte sur Ticketr. Merci de créer un compte ou de nous contacter à l'adresse suivante : contact@ticketr.fr",
    wellcomeOnTicketr: "Bienvenue sur Ticketr",
    pro: "Pro",
    trial: "Essai",
    companyName: "Nom de votre entreprise",
    yourFirstname: "Votre prénom",
    yourLastName: "Votre nom",
    yourEmail: "Votre email",
    pleaseEnterYourLastName: "Entrer votre nom",
    pleaseEnterYourFirstName: "Entrer votre prénom",
    plan: 'Plan',
    inputMustBeManual: '',
    errorWhileSubmitRequestForm: 'Une erreur est survenue pendant la soumission. Veuillez réessayer ; Si cela persiste, merci d\'envoyer un mail à contact@ticketr.fr',
    lastname: "Nom",
    contactPerson: "Personne de contact",
    pleaseEnterLastName: "Entrer un nom",
    pleaseEnterEmail: "Entrer une adresse e-mail",
    pleaseEnterBusinessName: "Entrer un nom d'entreprise",
    siretNumber: "Numéro de SIRET",
    pleaseEnterSiretNumber: "Entrer un numéro de SIRET",
    pleaseEnterCityName: "Entrer un nom de ville",
    pleaseEnterPostalCode: "Entrer un code postal",
    companyRegisteredFailed: "Une erreur s'est produite lors de la soumission. Veuillez vérifier le numéro de SIRET et réessayer.",
    companyRegisteredSuccessfully: "Entreprise enregistrée avec succès",
    trialPeriodMessage: "Vous utilisez actuellement la version d'essai de Ticketr. Certaines fonctionnalités peuvent être limitées.\n",
    venueDetails: "Details d'un lieu",
    contactPersonEmail: "Email personne contact",
    generateCurlCommand: "Générer une commande curl",
    copyFilter: "Copier le filtre",
    filterCopySuccessfully: "Filtre copié dans le presse papier!",
    companyRegisteredErrorDuplicatedEntry: "Une entreprise avec le même nom ou addresse e-mail existe déjà",

}
export default commonTranslation;