const sideBarTranslation = {
    dashboard: 'Dashboard',
    messenger: 'Messenger',
    events: 'Events',
    chanelManager: 'Channel Manager',
    statistics: 'Statistics',
    account: 'Accounts',
    companies: 'Companies',
    venues: "Venues",
    users: "Account/Users",
    registrationRequest:"Requests"
}
export default sideBarTranslation;
